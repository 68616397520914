import { useMemo } from 'react'
import logoTagline from 'assets/images/logo-tagline.jpg'
import logoNoTagline from 'assets/images/logo-no-tagline.jpg'
import logoNoTaglineWhite from 'assets/images/logo-no-tagline-white.png'
import Image from 'components/Image'
import classNames from 'classnames'
import Link from 'next/link'

interface LogoLinkProps {
  className?: string
  variant?: 'tagline' | 'no-tag-line' | 'white'
  size?: 'sm'
}

const LogoLink = ({
  className = 'max-w-[221px]',
  variant = 'no-tag-line',
  size,
}: LogoLinkProps) => {
  const logo = useMemo(() => {
    switch (variant) {
      case 'no-tag-line':
        return logoNoTagline
      case 'white':
        return logoNoTaglineWhite
      default:
        return logoTagline
    }
  }, [variant])

  return (
    <Link href="/">
      <div className={classNames(
        'focus:outline-none cursor-pointer relative',
        size
          ? ''
          : className,
        {
          'w-full': size,
          'max-w-[92px]': size === 'sm',
        },
      )}>
        <Image
          src={logo}
          alt="A.M.A Selections"
          className="object-contain"
          layout="responsive"
          width={221}
          height={121}
        />
      </div>
    </Link>
  )
}

export default LogoLink
